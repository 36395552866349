<template>
  <sb-app-page-list v-if="appId" :appIdByProps="appId" :companyIdByProps="companyId" read-only></sb-app-page-list>
  <div v-else-if="!loading && metaData==={} ">
    <v-tooltip bottom>
      <template v-slot:activator="{on}">
        <img class="projects-no-data" v-on="on" src="/img/empty.svg" alt="empty" />
      </template>
      <span>{{$t('iterator.no-records')}}</span>
    </v-tooltip>
  </div>
  <v-container v-else fluid>
    <v-row class="mt-6">
      <v-col v-for="n in 6" :key="n" cols="12" sm="6" md="6" lg="4" xl="3">
        <v-card outlined>
          <v-skeleton-loader type="image" :min-width="250" elevation="0"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      metaData: {},
      loading: false,
    };
  },
  computed: {
    companyId(){
        return this.$store.state.company.company?this.$store.state.company.company.id:undefined
    },
    ...mapState("SyncoBoxAppPageStoreModule", ["appId"]),
  },
};
</script>

<style lang='scss' scoped>
.projects-no-data {
  display: block;
  width: 50vw;
  max-width: 512px;
  margin: 32px auto 10px;
}

.text-white {
  color: #ffffff !important;
  opacity: 1;
}

.card-subtitle {
  opacity: 0.75;
}

::v-deep .v-responsive__sizer ~ .v-responsive__content {
  margin-left: 0 !important;
}

::v-deep .v-responsive__sizer {
  display: none;
}
</style>